
import FETCHING from "../library/fetching";
   import UMUM from "../library/umum";

export default {
  data() {
    return {
      leftDrawerOpen: true,
      nama : '',
      unit_kerja_nama : '',

      list_data : [],
      FETCHING : FETCHING,
      UMUM : UMUM,
    };
  },

  methods: {


    logout : function(){
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        this.$router.push('/login');
    },


    checkLength(data){
      if (data.length <= 0) {
        return false
      } else {
        return true        
      }
    },

    

    checkOtorisasi(){
      console.log(this.$store.state.url.checkAuth)
      fetch(this.$store.state.url.checkAuth, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            }
        })
            .then(res => res.json())
            .then(res_data => {
                // console.log(res_data.message)
                if (res_data.message=='SSILAHKAN LOGIN DULU..!!!') {
                  localStorage.removeItem('token');
                  localStorage.removeItem('profile');
                  this.$router.push('/login');
                  // console.log("harus logout")
                } else {
                  return false
                }
        });
    },



    async loadMenu(){

      var get_profile = JSON.parse(localStorage.profile);
      var profileku = get_profile.profile;
      var dataMenu =await this.FETCHING.postMasterMenuGetSideBar(profileku.ukpbj_doc)





      this.$store.state.list_menu = dataMenu

      // this.$store.commit('ubahState', { name : 'list_menu',  list : dataMenu});

      var cinta = {
        Data_cinta : 'adalah'
      }


      var data = []
      dataMenu.forEach(h => {
          if (h.type == 0) {
            data.push(h)
          } else {
            h.subItem.forEach(i => {
                if (i.type == 0) {
                  data.push(i)
                } else {
                  i.subItem.forEach(j => {
                      data.push(j)
                  });
                }
            });
          }
      });

      var obj = UMUM.ArrToObj(data)
      // this.$store.commit('ubahState', { name : 'aksesMenu',  list : obj});
      this.$store.state.aksesMenu = obj

      // console.log(dataMenu)

      

    },
  },


  mounted () {
    this.checkOtorisasi();
    var get_profile = JSON.parse(localStorage.profile);
    this.nama = get_profile.profile.nama;
    this.unit_kerja_nama = get_profile.profile.unit_kerja_nama;

    this.loadMenu();

    // console.log(profileku.Simpeg)
  },
  
};
