import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);





function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}










  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },



  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },


 
  {
    path: '/menuList',
    name: 'menuList',
    component: () => import('../views/dataMaster/menuList.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/klpUsers',
    name: 'klpUsers',
    component: () => import('../views/dataMaster/klpUsers.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => import('../views/dataMaster/registrasi.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterJenisArsip',
    name: 'masterJenisArsip',
    component: () => import('../views/dataMaster/masterJenisArsip.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterMetodePemilihan',
    name: 'masterMetodePemilihan',
    component: () => import('../views/dataMaster/masterMetodePemilihan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterPelaksanaan',
    name: 'masterPelaksanaan',
    component: () => import('../views/dataMaster/masterPelaksanaan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterSumberDana',
    name: 'masterSumberDana',
    component: () => import('../views/dataMaster/masterSumberDana.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterTypeDokumen',
    name: 'masterTypeDokumen',
    component: () => import('../views/dataMaster/masterTypeDokumen.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/unitKerja',
    name: 'unitKerja',
    component: () => import('../views/dataMaster/unitKerja.vue'),
    beforeEnter: isLoggedIn,
  },
  
  {
    path: '/arsipPBJ',
    name: 'arsipPBJ',
    component: () => import('../views/arsip_digital/arsipPBJ.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/arsipUmum',
    name: 'arsipUmum',
    component: () => import('../views/arsip_digital/arsipUmum.vue'),
    beforeEnter: isLoggedIn,
  },
  
  {
    path: '/arsipkanDirektori',
    name: 'arsipkanDirektori',
    component: () => import('../views/pbj/arsipkanDirektori.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kontrakOPD',
    name: 'kontrakOPD',
    component: () => import('../views/pbj/kontrakOPD.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/uploadDokumenPBJ',
    name: 'uploadDokumenPBJ',
    component: () => import('../views/pbj/uploadDokumenPBJ.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/verifikasiKasubag',
    name: 'verifikasiKasubag',
    component: () => import('../views/pbj/verifikasiKasubag.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/uploadDokumenUmum',
    name: 'uploadDokumenUmum',
    component: () => import('../views/umum/uploadDokumenUmum.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/rup_draft',
    name: 'rup_draft',
    component: () => import('../views/rup/rup_draft.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/rup_final',
    name: 'rup_final',
    component: () => import('../views/rup/rup_final.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/paket_penyedia',
    name: 'paket_penyedia',
    component: () => import('../views/paket/paket_penyedia.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/nonTender',
    name: 'nonTender',
    component: () => import('../views/paket/nonTender.vue'),
    beforeEnter: isLoggedIn,
  },


  {
    path: '/program',
    name: 'program',
    component: () => import('../views/program_kegiatan/program.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kegiatan',
    name: 'kegiatan',
    component: () => import('../views/program_kegiatan/kegiatan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/kegiatanSub',
    name: 'kegiatanSub',
    component: () => import('../views/program_kegiatan/kegiatanSub.vue'),
    beforeEnter: isLoggedIn,
  },


  {
    path: '/lap_rekap_paket',
    name: 'lap_rekap_paket',
    component: () => import('../views/laporan/rekap/lap_rekap_paket.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/lap_progres_pbj',
    name: 'lap_progres_pbj',
    component: () => import('../views/laporan/kke/lap_progres_pbj.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/lap_rup_pbj',
    name: 'lap_rup_pbj',
    component: () => import('../views/laporan/kke/lap_rup_pbj.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/lap_iktisar_rup',
    name: 'lap_iktisar_rup',
    component: () => import('../views/laporan/kke/lap_iktisar_rup.vue'),
    beforeEnter: isLoggedIn,
  },

  

  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    beforeEnter: isLoggedIn,
  },


]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
