


const batang = (id, judul, data, satuan)=>{
    Highcharts.chart(id, {
        chart: {
            type: 'column',
            backgroundColor: 'transparent'
        },
        title: {
            align: 'left',
            text: judul
        },
        subtitle: {
            align: 'left',
            text: 'Untuk melihat data lengkapnya: <a href="#/about" target="_blank">Click disini</a>'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Total xxx'
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f} '+satuan
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> of total<br/>'
        },

        series: [
          {
              name: 'Jenis Pengadaan ('+satuan+')',
              colorByPoint: true,
              data : data
            //   data: 
            //   [
            //     {name : "Tender", y : 100},
            //     {name : "Pengadaan langsung", y : 100},
            //     {name : "Penunjukan Langsung", y : 100},
            //     {name : "Swakelola", y : 100},
            //     {name : "e-Purchasing", y : 100},
            //   ]
          }
        ],

        exporting: {
            showTable: true
          }
        // drilldown: {
        //   breadcrumbs: {
        //       position: {
        //           align: 'right'
        //       }
        //   },
        // //   series: [
        // //       {
        // //           name: 'Chrome',
        // //           id: 'Chrome',
        // //           data: [
        // //               [
        // //                   'v65.0',
        // //                   0.1
        // //               ],
        // //               [
        // //                   'v64.0',
        // //                   1.3
        // //               ],
        // //               [
        // //                   'v63.0',
        // //                   53.02
        // //               ],
        // //               [
        // //                   'v62.0',
        // //                   1.4
        // //               ],
        // //               [
        // //                   'v61.0',
        // //                   0.88
        // //               ],
        // //               [
        // //                   'v60.0',
        // //                   0.56
        // //               ],
        // //               [
        // //                   'v59.0',
        // //                   0.45
        // //               ],
        // //               [
        // //                   'v58.0',
        // //                   0.49
        // //               ],
        // //               [
        // //                   'v57.0',
        // //                   0.32
        // //               ],
        // //               [
        // //                   'v56.0',
        // //                   0.29
        // //               ],
        // //               [
        // //                   'v55.0',
        // //                   0.79
        // //               ],
        // //               [
        // //                   'v54.0',
        // //                   0.18
        // //               ],
        // //               [
        // //                   'v51.0',
        // //                   0.13
        // //               ],
        // //               [
        // //                   'v49.0',
        // //                   2.16
        // //               ],
        // //               [
        // //                   'v48.0',
        // //                   0.13
        // //               ],
        // //               [
        // //                   'v47.0',
        // //                   0.11
        // //               ],
        // //               [
        // //                   'v43.0',
        // //                   0.17
        // //               ],
        // //               [
        // //                   'v29.0',
        // //                   0.26
        // //               ]
        // //           ]
        // //       },
        // //       {
        // //           name: 'Firefox',
        // //           id: 'Firefox',
        // //           data: [
        // //               [
        // //                   'v58.0',
        // //                   1.02
        // //               ],
        // //               [
        // //                   'v57.0',
        // //                   7.36
        // //               ],
        // //               [
        // //                   'v56.0',
        // //                   0.35
        // //               ],
        // //               [
        // //                   'v55.0',
        // //                   0.11
        // //               ],
        // //               [
        // //                   'v54.0',
        // //                   0.1
        // //               ],
        // //               [
        // //                   'v52.0',
        // //                   0.95
        // //               ],
        // //               [
        // //                   'v51.0',
        // //                   0.15
        // //               ],
        // //               [
        // //                   'v50.0',
        // //                   0.1
        // //               ],
        // //               [
        // //                   'v48.0',
        // //                   0.31
        // //               ],
        // //               [
        // //                   'v47.0',
        // //                   0.12
        // //               ]
        // //           ]
        // //       },
        // //       {
        // //           name: 'Internet Explorer',
        // //           id: 'Internet Explorer',
        // //           data: [
        // //               [
        // //                   'v11.0',
        // //                   6.2
        // //               ],
        // //               [
        // //                   'v10.0',
        // //                   0.29
        // //               ],
        // //               [
        // //                   'v9.0',
        // //                   0.27
        // //               ],
        // //               [
        // //                   'v8.0',
        // //                   0.47
        // //               ]
        // //           ]
        // //       },
        // //       {
        // //           name: 'Safari',
        // //           id: 'Safari',
        // //           data: [
        // //               [
        // //                   'v11.0',
        // //                   3.39
        // //               ],
        // //               [
        // //                   'v10.1',
        // //                   0.96
        // //               ],
        // //               [
        // //                   'v10.0',
        // //                   0.36
        // //               ],
        // //               [
        // //                   'v9.1',
        // //                   0.54
        // //               ],
        // //               [
        // //                   'v9.0',
        // //                   0.13
        // //               ],
        // //               [
        // //                   'v5.1',
        // //                   0.2
        // //               ]
        // //           ]
        // //       },
        // //       {
        // //           name: 'Edge',
        // //           id: 'Edge',
        // //           data: [
        // //               [
        // //                   'v16',
        // //                   2.6
        // //               ],
        // //               [
        // //                   'v15',
        // //                   0.92
        // //               ],
        // //               [
        // //                   'v14',
        // //                   0.4
        // //               ],
        // //               [
        // //                   'v13',
        // //                   0.1
        // //               ]
        // //           ]
        // //       },
        // //       {
        // //           name: 'Opera',
        // //           id: 'Opera',
        // //           data: [
        // //               [
        // //                   'v50.0',
        // //                   0.96
        // //               ],
        // //               [
        // //                   'v49.0',
        // //                   0.82
        // //               ],
        // //               [
        // //                   'v12.1',
        // //                   0.14
        // //               ]
        // //           ]
        // //       }
        // //   ]
        // }
      }
    )
    // chart.update({
    //     exporting: {
    //       showTable: !chart.options.exporting.showTable
    //     }
    //   });
}


const batangSide = (id, judul, categories, data, satuan) => {
    Highcharts.chart(id, {
        chart: {
            type: 'bar',
            backgroundColor: 'transparent'
        },
        title: {
            text: judul,
            align: 'left'
        },
        subtitle: {
            align: 'left',
            text: 'Untuk melihat data lengkapnya: <a href="#/about" target="_blank">Click disini</a>'
        },
        xAxis: {
            categories: categories,
            // categories: ['Dikecualikan ', 'e-Katalog', 'Pengadaan langsung', 'Penunjukan Langsung', 'Seleksi', 'Tender & e-Tender', 'Tender Cepat', 'Swakelola'],
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 0
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                // text: 'Population (Rupiah)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        tooltip: {
            valueSuffix: ''
            // valueSuffix: ' Rupiah'
        },
        plotOptions: {
            bar: {
                borderRadius: '50%',
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            }
        },
        // legend: {
        //     layout: 'vertical',
        //     align: 'right',
        //     verticalAlign: 'top',
        //     x: -40,
        //     y: 80,
        //     floating: true,
        //     borderWidth: 1,
        //     backgroundColor:
        //         Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        //     shadow: true
        // },
        credits: {
            enabled: false
        },
        series: data,
        // series: [

        //     {
        //         name: 'Barang',
        //         data: [631, 727, 3202, 721, 721, 746, 746, 746]
        //     }, 
        //     {
        //         name: 'Konstruksi',
        //         data: [814, 841, 3714, 726, 721, 746, 746, 746]
        //     }, 
        //     {
        //         name: 'Jasa Lainnya',
        //         data: [1276, 1007, 4561, 746, 721, 746, 746, 746]
        //     },
        //     {
        //         name: 'Jasa',
        //         data: [1276, 1007, 4561, 746, 721, 746, 746, 746]
        //     },
        //     {
        //         name: 'Konsultansi',
        //         data: [1276, 1007, 4561, 746, 721, 746, 746, 746]
        //     },
        // ],
        exporting: {
            showTable: true
          }
    });
    
}


module.exports = {

    batang : batang,
    batangSide : batangSide,

}