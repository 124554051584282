

import FETCHING from '../library/fetching'
import UMUM from '../library/umum'
import DATA_MASTER from '../library/dataMaster'
import HIGH_CHART from '../library/highChart'



export default {
  data() {
    return {
      alert: false,
      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ],
      date : '',

      filterku : {
        kd_satker : '',
        tahun_anggaran  : '',
      },


      show_chart : false,

      FETCHING : FETCHING,
      UMUM : UMUM,
      DATA_MASTER : DATA_MASTER,
      HIGH_CHART : HIGH_CHART,
    }
  },
  methods: {
    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },


    chartRincianPaket : async function(list_rekap_paket){


      var categories = ['Dikecualikan ', 'e-Katalog', 'Pengadaan langsung', 'Penunjukan Langsung', 'Seleksi', 'Tender & e-Tender', 'Tender Cepat', 'Swakelola'] ;
      var data =[
                    {
                        name: 'Barang',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.purchasing.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.seleksi.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.tender.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.tender_cepat.val.rup_final_count_pkt_barang, 
                          list_rekap_paket.swakelola.val.rup_final_count_pkt_barang
                        ]
                    }, 
                    {
                        name: 'Konstruksi',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.purchasing.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.seleksi.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.tender.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.tender_cepat.val.rup_final_count_pkt_konstruksi, 
                          list_rekap_paket.swakelola.val.rup_final_count_pkt_konstruksi
                        ]
                    }, 
                    {
                        name: 'Jasa Lainnya',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.purchasing.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.seleksi.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.tender.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.tender_cepat.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.swakelola.val.rup_final_count_pkt_jasa_lainnya
                        ]
                    },
                    {
                        name: 'Jasa',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.purchasing.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.seleksi.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.tender.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.tender_cepat.val.rup_final_count_pkt_jasa_lainnya, 
                          list_rekap_paket.swakelola.val.rup_final_count_pkt_jasa_lainnya
                        ]
                    },
                    {
                        name: 'Konsultansi',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.purchasing.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.seleksi.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.tender.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.tender_cepat.val.rup_final_count_pkt_jasa_konsultansi, 
                          list_rekap_paket.swakelola.val.rup_final_count_pkt_jasa_konsultansi
                        ]
                    },
                ]
      HIGH_CHART.batangSide('chartRincianPaket', 'Rincian Jumlah Paket Berdasarkan Jenis Pengadaan', categories, data, "-")
    },

    chartRincianNilai : async function(list_rekap_paket){

      var categories = ['Dikecualikan ', 'e-Katalog', 'Pengadaan langsung', 'Penunjukan Langsung', 'Seleksi', 'Tender & e-Tender', 'Tender Cepat', 'Swakelola'] ;
      var data =[
                    {
                        name: 'Barang',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.purchasing.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.seleksi.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.tender.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.tender_cepat.val.rup_final_sum_pkt_barang, 
                          list_rekap_paket.swakelola.val.rup_final_sum_pkt_barang
                        ]
                    }, 
                    {
                        name: 'Konstruksi',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.purchasing.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.seleksi.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.tender.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.tender_cepat.val.rup_final_sum_pkt_konstruksi, 
                          list_rekap_paket.swakelola.val.rup_final_sum_pkt_konstruksi
                        ]
                    }, 
                    {
                        name: 'Jasa Lainnya',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.purchasing.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.seleksi.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.tender.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.tender_cepat.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.swakelola.val.rup_final_sum_pkt_jasa_lainnya
                        ]
                    },
                    {
                        name: 'Jasa',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.purchasing.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.seleksi.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.tender.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.tender_cepat.val.rup_final_sum_pkt_jasa_lainnya, 
                          list_rekap_paket.swakelola.val.rup_final_sum_pkt_jasa_lainnya
                        ]
                    },
                    {
                        name: 'Konsultansi',
                        data: [
                          list_rekap_paket.dikecualikan.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.purchasing.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.pengadaan_langsung.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.penunjukan_langsung.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.seleksi.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.tender.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.tender_cepat.val.rup_final_sum_pkt_jasa_konsultansi, 
                          list_rekap_paket.swakelola.val.rup_final_sum_pkt_jasa_konsultansi
                        ]
                    },
                ]
      HIGH_CHART.batangSide('chartRincianNilai', 'Rincian Jumlah Nilai Paket Berdasarkan Jenis Pengadaan', categories, data, "-")
    },

    chartTotalPaket : async function(list_rekap_paket){

      console.log(list_rekap_paket)

      var data =[
                  {name : "Dikecualikan", y : list_rekap_paket.dikecualikan.val.rup_final_total_count_pkt},
                  {name : "e-Katalog", y : list_rekap_paket.purchasing.val.rup_final_total_count_pkt},
                  {name : "Pengadaan langsung", y : list_rekap_paket.pengadaan_langsung.val.rup_final_total_count_pkt},
                  {name : "Penunjukan Langsung", y : list_rekap_paket.penunjukan_langsung.val.rup_final_total_count_pkt},
                  {name : "Seleksi", y : list_rekap_paket.seleksi.val.rup_final_total_count_pkt},
                  {name : "Tender & e-Tender", y : list_rekap_paket.tender.val.rup_final_total_count_pkt},
                  {name : "Tender Cepat", y : list_rekap_paket.tender_cepat.val.rup_final_total_count_pkt},
                  {name : "Swakelola", y : list_rekap_paket.swakelola.val.rup_final_total_count_pkt},
                ]
            

      HIGH_CHART.batang('chartTotalPaket', 'Jumlah Data Paket Berdasarkan Jenis Pengadaan', data, "Pkt")

    },

    chartTotalNilai : async function(list_rekap_paket){
      var data =[
                  {name : "Dikecualikan", y : list_rekap_paket.dikecualikan.val.rup_final_total_sum_pkt},
                  {name : "e-Katalog", y : list_rekap_paket.purchasing.val.rup_final_total_sum_pkt},
                  {name : "Pengadaan langsung", y : list_rekap_paket.pengadaan_langsung.val.rup_final_total_sum_pkt},
                  {name : "Penunjukan Langsung", y : list_rekap_paket.penunjukan_langsung.val.rup_final_total_sum_pkt},
                  {name : "Seleksi", y : list_rekap_paket.seleksi.val.rup_final_total_sum_pkt},
                  {name : "Tender & e-Tender", y : list_rekap_paket.tender.val.rup_final_total_sum_pkt},
                  {name : "Tender Cepat", y : list_rekap_paket.tender_cepat.val.rup_final_total_sum_pkt},
                  {name : "Swakelola", y : list_rekap_paket.swakelola.val.rup_final_total_sum_pkt},
                ]
      HIGH_CHART.batang('chartTotalNilai', 'Jumlah Nilai Paket Berdasarkan Jenis Pengadaan', data, "-")
    },


    chartPersentasePaketBarang : function(){
      Highcharts.chart('chartPersentasePaketBarang', {
      chart: {
          type: 'pie',
          backgroundColor: 'transparent'
      },
      title: {
          text: 'TOTAL PERSENTASE RUP'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      }
      },
      series: [{
          name: 'Persentase',
          colorByPoint: true,
          data: [
            {
              name: 'Draft',
              y: 52.08,
              color:'#1e88e5',
              // sliced: true,
              // selected: true
            }, 
            {
              name: 'Final',
              y: 22.92,
              color:'#7cb342',
            },

          
          ]
        }]
      });



    },

    chartPersentaseNilaiBarang : function(){
      

      Highcharts.chart('chartPersentaseNilaiBarang', {
      chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
          }
      },
      title: {
          text: 'TOTAL PERSENTASE TENDER'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                  enabled: true,
                  format: '{point.name}'
              }
          }
          },
          series: [{
              type: 'pie',
              name: 'Persentase',
              data: [
                  {
                    name: 'Proses',
                    y: 52.08,
                    color:'#1e88e5',
                    // sliced: true,
                    // selected: true
                  }, 
                  {
                    name: 'Final',
                    y: 22.92,
                    color:'#7cb342',
                  },
              ]
          }]
      });









    },


    loadAsync : async function(){


      this.show_chart = false;
      var list_rekap_paket = await FETCHING.postRekapPaket(this.filterku.tahun_anggaran, this.filterku.kd_satker);
      this.show_chart = true;

      setTimeout(() => {

        

        this.chartRincianPaket(list_rekap_paket);
          this.chartRincianNilai(list_rekap_paket);
    
          this.chartTotalPaket(list_rekap_paket);
          this.chartTotalNilai(list_rekap_paket);
    
    
          this.chartPersentasePaketBarang();
          this.chartPersentaseNilaiBarang();


      }, 100);
      



      // console.log(list_rekap_paket)



    },


    

    async button(){
      await setTimeout(() => {
        console.log('ini query pembacaannya')
      }, 2000);
      console.log('telASO')
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================
    autocomplete_unit_kerja : function (val, update) {
        update(() => {
          if (val === '') {}
          else {DATA_MASTER.getUnitKerjaAuto2(val, this.filterku.kd_satker)}
        })
    },

    // ====================================== CONTOH AUTOCOMPLETE ====================================







  },

  mounted () {

    const d = new Date();
    let year = d.getFullYear();

    this.filterku.tahun_anggaran = year



    var profilex = JSON.parse(localStorage.profile).profile
    this.filterku.kd_satker = profilex.kd_satker
    DATA_MASTER.getUnitKerjaAuto2('', this.filterku.kd_satker)



    this.loadAsync()


    
    this.button();
  },
}
