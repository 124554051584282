import Vue from 'vue'
import Vuex from 'vuex'

import { Loading,  QSpinnerFacebook,  } from 'quasar'
import { Notify } from 'quasar'

Vue.use(Vuex)

// var URL = 'http://localhost:5039/'
// var URL = 'http://192.168.1.20:5039/'
var URL = 'http://serverr-sm-pbj.konaweselatankab.go.id/'

export default new Vuex.Store({
  state: {
    coordinat : {
      lat:-4.034694, 
      lng: 122.484263
    },
    btn : {
      add : false,
      edit : false,
      remove : false,
    },
    url : {
      URL_APP : URL,
      checkAuth : URL + 'api/v1/checkAuth/',

      URL_simpeg_biodata : URL+'api/v1/dm_biodata/',
      URL_simpeg_unit_kerja: URL+'api/v1/dm_unitKerja/',
      URL_simpeg_instansi : URL+'api/v1/dm_instansi/',

      URL_DM_REGISTER : URL+'api/v1/dm_registrasi/',
      URL_DM_MENU : URL+'api/v1/dm_menuList/',
      URL_DM_KLP_USERS : URL+'api/v1/dm_kelompokUsers/',

      URL_DM_JENIS_ARSIP : URL+'api/v1/dm_masterJenisArsip/',
      URL_DM_METODE_PEMILIHAN : URL+'api/v1/dm_masterMetodePemilihan/',
      URL_DM_PELAKSANAAN : URL+'api/v1/dm_masterPelaksanaan/',
      URL_DM_SUMBER_DANA : URL+'api/v1/dm_masterSumberDana/',
      URL_DM_TYPE_DOKUMEN : URL+'api/v1/dm_masterTypeDokumen/',
      URL_DM_PROGRAM : URL+'api/v1/dm_masterProgram/',
      URL_DM_KEGIATAN : URL+'api/v1/dm_masterKegiatan/',
      URL_DM_KEGIATAN_SUB : URL+'api/v1/dm_masterKegiatanSub/',


      URL_CT_DASHBOARD_REKAP : URL+'api/v1/das_rekap_paket/',

      URL_CT_RUP_DRAFT : URL+'api/v1/ct_rup_draft/',
      URL_CT_RUP_FINAL : URL+'api/v1/ct_rup_final/',
      URL_CT_PAKET_PENYEDIA : URL+'api/v1/ct_paket_penyedia/',

    },

    list_rekap_paket : [],

    // ====================================== CONTOH AUTOCOMPLETE ====================================
    list_contoh_autocomplete : [],
    list_unit_kerja: [],
    list_instansi: [],
    // ====================================== CONTOH AUTOCOMPLETE ====================================

    list_menu : null,
    aksesMenu : {},

    page_first: 1,
    page_last: 0,
    cari_value: "",
    cek_load_data : true,

    type : [
      {id : 0, uraian : 'Single Menu'},
      {id : 1, uraian : 'Multy Menu'}
    ],

    tahun : [2021, 2022, 2023, 2024, 2025],
    mtd_pengadaan : [
      "Dikecualikan",
      "e-Purchasing",
      "Pengadaan Langsung",
      "Penunjukan Langsung",
      "Seleksi",
      "Tender",
      "Tender Cepat",
    ],
    jenis_pengadaan : [
      "Barang",
      "Jasa Konsultansi",
      // "Jasa Konsultansi;Pekerjaan Konstruksi",
      "Jasa Lainnya",
      "Pekerjaan Konstruksi",
    ],

    
  },
  mutations: {
    listJeniskategorilokasi(state){

      fetch(state.url.URL_MasterKategoriLokasi, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
        .then(res => res.json())
        .then(res_data => {
          state.list_MasterKategoriLokasi = res_data;
      });

    },

    listApelJenis(state){

      fetch(state.url.URL_apelJenis, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
        .then(res => res.json())
        .then(res_data => {
          // console.log(res_data)
          state.list_ApleJenis = res_data;
      });

    },

    getStorage(state){
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja; 
    },
    shoWLoading(){
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'yellow',
        spinnerSize: 140,
        backgroundColor: 'purple',
        // message: 'Loading... Tunggu beberapa saat, system sedang menyesuaikan akun anda..!',
        // messageColor: 'white'
      })
    },
    hideLoading(){
      Loading.hide()
    },
    shoWNotify(state, message, color, icon){
      Notify.create({
        message: message,
        color: color,
        position : 'top-right',
        icon:icon
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
